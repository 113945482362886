import { Link } from "gatsby";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { StaticImage } from "gatsby-plugin-image";
import { BsFillPersonFill } from "react-icons/bs";
import { FaHeart, FaMapMarkerAlt, FaRegHeart, FaTrain } from "react-icons/fa";
import CartContext from "../context/CartContext";
import CardPillTagSection from "./card-pill-tag-section";
import ImageSlider from "./image-slider";
import PopupForm from "./popup-form";
import { Button, Modal } from "react-bootstrap";
import ExpertCard from "./expert-card";
import ExpertCardPopup from "./expert-card-popup";
import { MdOutlineClear } from "react-icons/md";
import PopupFormViewing from "./popup-form-viewing";
import RequestQuoteForm from "./request-quote-form";
import BookViewingForm from "./book-a-viewing-form";
import BookViewingFormMultiStep from "./book-a-viewing-form-multi-step";

const slugify = require("slugify");

const createSlug = (value) => {
	// Ensure the value is a string or provide a default empty string if it's not
	const stringValue = value ? String(value) : "";
	return slugify(stringValue);
};

function round5(x) {
	return Math.ceil(x / 5) * 5;
}
const ShortlistPageCard = ({ property, index, documentLink, date }) => {
	const {
		cart,
		onLocationChangeScroll,
		getDistanceFromLatLonInKm,
		OnAddToCart,
		OnRemoveFromCart,
		distancePoint,
		selectedLocation,
		budgetPerDesk,
		numberOfPeople,
	} = React.useContext(CartContext);

	var floors = [];
	if (property?.floorsAvailable !== null) {
		floors =
			property?.floorsAvailable
				?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
				.slice() || [];
	}

	// const refTb = useRef();
	// const tbShow = useOnScreen(refTb, "0px");
	// useEffect(() => {
	// 	onLocationChangeScroll(
	// 		property.locationLatitude,
	// 		property.locationLongitude
	// 	);
	// }, [tbShow]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	const handleHeartClick = (clickEvent, shortlistProp, addOrRemove) => {
		clickEvent.preventDefault();
		addOrRemove(shortlistProp);
	};

	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formSubmitted2, setFormSubmitted2] = useState(false);

	const handleFormSuccess = () => {
		setFormSubmitted(true);
	};
	const handleFormSuccess2 = () => {
		setFormSubmitted2(true);
	};

	const [vidShow, setVidShow] = useState(false);
	const [formShow, setFormShow] = useState(false);

	const handleVidShow = (e) => {
		e.preventDefault();
		setVidShow(true);
	};
	const handleVidClose = () => setVidShow(false);

	const handleFormShow = (e) => {
		e.preventDefault();
		setFormShow(true);
	};
	const handleFormClose = () => setFormShow(false);

	const lowerCapacity = Math.max(property?.desksFrom, numberOfPeople?.min);

	return (
		<div className="  px-0 mx-0 position-relative">
			<div
				className="bg-white h-100 px-0 mx-0 listings-property-card-shadow rounded-3"
				onMouseEnter={() => {
					onLocationChangeScroll(
						property?.locationLatitude,
						property?.locationLongitude
					);
				}}
			>
				<div>
					<Row className="p-3 mx-0 g-0">
						<Col lg={6}>
							<ImageSlider imageDataArr={property?.photos} height={"100%"} />
						</Col>
						<Col
							className="ps-lg-4 text-dark-bg position-relative"
							style={{ minHeight: "100%" }}
							lg={6}
						>
							<h5 className="py-1">{property?.name}</h5>
							{createSlug(property?.city).toLowerCase() === "london" && (
								<div>
									{property?.subwayStation1 !== null && (
										<div
											style={{ fontSize: "90%" }}
											className="d-flex align-items-start pb-2"
										>
											<FaTrain className="me-2 mt-1 text-green" />
											<p className="small-text d-flex align-items-center pb-0 mb-0">
												<span className="montserrat-bold me-1 text-dark-b">
													{property?.subwayStation1}
												</span>{" "}
												-{" "}
												{(
													property?.subwayStation1DistanceKm * 0.621371
												).toFixed(2)}
												mi{" "}
												<span className="d-none d-lg-inline-block ms-1">
													({property?.subwayStation1DurationMins} walk)
												</span>
											</p>
										</div>
									)}
								</div>
							)}

							<Row className="pb-0 pt-0 my-2 align-items-center">
								<CardPillTagSection
									url={`/listings/${slugify(property?.name)}-${
										property?.fixedId
									}`}
									features={property?.features}
								/>
							</Row>
							<blockquote className="mb-2">
								<div className="d-flex justify-content-between align-items-center">
									<p
										style={{ fontSize: "70%" }}
										className="mb-0 montserrat-medium "
									>
										Viewing Date & Time:{" "}
									</p>
									<p
										style={{ fontSize: "70%" }}
										className="mb-0 montserrat-medium"
									>
										{date === "TBC TBC" ? "TBC" : date}
									</p>
								</div>
							</blockquote>
							<div
								className="w-100"
								style={{
									height: `${documentLink?.length > 0 ? "88px" : "42px"}`,
								}}
							></div>
							<div className="w-100 position-absolute start-0 bottom-0 ps-lg-4">
								<Button
									href={`/listings/${slugify(property?.name)}-${
										property?.fixedId
									}`}
									className={`w-100 fw-bold `}
									variant="secondary"
								>
									View details
								</Button>
								{documentLink?.length > 0 && (
									<Button
										href={documentLink}
										target="_blank"
										rel="noreferrer"
										variant="transparent"
										className="px-4 w-100 mt-2"
									>
										View my quote
									</Button>
								)}
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default ShortlistPageCard;
